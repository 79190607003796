import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineMovieCreation } from "react-icons/md"
import { MdDomainVerification } from "react-icons/md";
const MarketingNavbarComponent = () => {
    return (
        <div className="categorie-header">
            <NavLink to="/dashboard/masters/Auditions" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineMovieCreation size={24} className="childnav-icon" />
                <p className="childnav-name">Auditions </p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/verified" className="categorie-subtitle" ><div className="child-title">
                <MdDomainVerification size={24} className="childnav-icon" />
                <p className="childnav-name">Verified</p>
            </div></NavLink>
        </div>
    )
}

export { MarketingNavbarComponent }
