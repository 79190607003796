import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit, MdOutlineRemoveRedEye } from "react-icons/md"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import "./master_categories.css";
import { Button, Modal } from 'react-bootstrap';
import Masters from "../Components/masters.json"
import { MarketingNavbarComponent } from "../Components/navbar/marketingnav";
import { BiSearch } from "react-icons/bi";
import PreviewModal from "../Components/PopupModal/PreviewModal";
const VerifiedList = () => {
    const [Data, SetData] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const [selectedImage, setSelectedImage] = useState({image:"",id:0});
    const [RowId, SetRowId] = useState()
    const [search, SetSearch] = useState({
        search: ""
    })
    let navigate = useNavigate();
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        axiosConfig.get(`/accounts/verified-profile/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/accounts/verified-profile/?page=${currentPage}&page_size=${RowPerPage}`);
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage]);
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const handleRowClick = (row) => {
        navigate(`/dashboard/masters/verified/${row}/edit`)
    }
    const [show, setShow] = useState(false)
    const [showImage, setShowImage] = useState(false)
    const handleClose = () => {
        setShow(false);
        setFormdata({})
    }
    const handleShow = () => {
        setShow(true);
    }
    const Close = () => {
        setShowImage(false);
        setSelectedImage({image:"",id:0});
    }
    const  Show = (image,id) => {
        setSelectedImage({image:image,id:id});
        setShowImage(true);
    }
    const [formData, setFormdata] = useState({});
    const formSubmit = (Data, setFieldErrors) => {
        console.log(formData, "jdshf", Data)
        axiosConfig.post("/accounts/verified-profile/", Data).then(res => {
            console.log(res)
            handleClose()
            setpopupType("success");
        }).catch(error => {
            console.log(error.response)
        })
    };
    function formatDateOfBirth(dateString) {
        if (!dateString) return ''; // Check if dateString is null, undefined, or empty
    
        const date = new Date(dateString);
    
        if (isNaN(date)) return ''; // Check if the date is invalid
    
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();
    
        return `${month} ${day}, ${year}`;
    }
    
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Name",
            selector: "name",
            cell: row => row.name ? row.name : "--",
            sortable: true
        },
        {
            name: "Mobile Number",
            selector: "mobile_number",
            cell: row => row.mobile_number,
            sortable: true
        },
        {
            name: "Gender",
            selector: "gender",
            cell: row => row.gender,
            sortable: true
        },
        {
            name: "Email",
            selector: "email",
            cell: row => row.email,
            sortable: true
        },
        {
            name: "Date Of Bitrh",
            selector: "dob",
            cell: row =>formatDateOfBirth(row.dob),
            sortable: true
        },
        {
            name: "Govt Id Proof",
            selector: "govt_id_proof",
            cell: row => row.govt_id_proof,
            sortable: true
        },
        // {
        //     name: "govt id Front Copy",
        //     selector: row => <>
        //         <img className="image" src={row.govt_id_proof_front_copy} alt={row.govt_id_proof_front_copy} onClick={()=>Show(row.govt_id_proof_front_copy,row.id)}/>
        //     </>,
        //     sortable: false

        // },
        // {
        //     name: "govt Id Back Copy",
        //     selector: row => <>
        //         <img className="image" src={row.govt_id_proof_back_copy} alt={row.govt_id_proof_back_copy} onClick={()=>Show(row.govt_id_proof_back_copy,row.id)}/>
        //     </>,
        //     sortable: false

        // },
        {
            name: "Status",
            selector: "status",
            cell: row => row.status,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    // catedit(row.id)
                    // setModalTitle("Edit Item");
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/accounts/verified-profile/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                return (
                    <>
                        <div className="row" >
                            <div className="col-4">
                                <MdOutlineRemoveRedEye className="dashboard-tabe-data-actions" onClick={() => handleRowClick(row.id)} />
                            </div>
                            <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                            </div>
                        </div>

                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <MarketingNavbarComponent />
                    <div className="childnav-content">
                        {/* <div className="d-flex align-items-center">
                            <div className="col-4 input-cat-with-icon">
                                <span className="icon-product"><BiSearch /></span>
                                <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                            </div>
                        </div> */}
                        <div className="table-responsive marginTop">
                            <DataTable
                                columns={columns}
                                data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                // onSort={handleSort}
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                    </div>
                </div>
                {/* {popupType != "" &&
                    <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="/dashboard/masters/products" />
                }
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                } */}
                {showImage&&<PreviewModal show={showImage} handleClose={Close} selectedImage={selectedImage}/>}
                <Modal
                    size="lg"
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Create Audition
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormParser modelObject={Masters.Audition} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle="Create Audition" button="btn-block" />
                    </Modal.Body>
                </Modal>

            </DashboardLayout >
        </>
    )
}
export { VerifiedList }