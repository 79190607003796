import React, { useEffect, useState } from 'react';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { Chart } from "chart.js/auto"
import Card from 'react-bootstrap/Card';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { DashboardLayout } from './DashboardLayout';
import axiosConfig from "../../Service/axiosConfig";

const ChartTable = () => {
  let today_date = (new Date()).toISOString().split('T')[0];
  const [Data, SetData] = useState([])
  let data=localStorage.getItem("user_data")
  const [totalInfo, SetTotalInfo] = useState({})
  const [clinics, setClinics] = useState()
  const [services, setServices] = useState()
  const [doctors, setDoctors] = useState()
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState({})
  const [dates, setDates] = useState([])
  const [firstValues, setfirstValues] = useState([])
  const [specialities, setspecialities] = useState([])
  const [followupValues, setfollowupValues] = useState([])
  const [count, setcount] = useState([])
  const [UserData, SetUserData] = useState({})

  const options = {
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true,
        text: "Datewise"
      }
    },
    responsive: true
  }
  // const user_data = JSON.parse(localStorage.getItem('profile_data'));
   const [formData, setFormData] = useState({
     fromDate: today_date,
     toDate: today_date,
     clinic:"",
     service: '',
     doctor:""
   });

  const getUserData = async() =>{
    const user_data = JSON.parse(localStorage.getItem('profile_data'));
    SetUserData(user_data);  
    if(user_data.user_type==="doctor"){
      setFormData({...formData ,["doctor"]:user_data.doctor_id})
      setFormData({...formData ,["clinic"]:user_data.clinic_id})
      GetData();
    }
    if(user_data.user_type==="staff"){
      setFormData({...formData ,["clinic"]:user_data.clinic_id})
      GetData();
    }
    
}
  const GetData = async () => {
    try {
      const responce = await axiosConfig.get(`/clinics/display_data/?from_date=${formData.fromDate}&to_date=${formData.toDate}&clinic_id=${formData.clinic}&sepecality_id=${formData.service}&doctor_id=${formData.doctor}`)
      console.log("dasboard data:", responce.data)
      SetData(responce.data)
      SetTotalInfo(responce.data.total_info)
      let dates = []
      responce.data.datewise.forEach(item => {
        dates.push(item.date)
      })
      setDates(dates)
      let firstValues = []
      responce.data.datewise.forEach(item => {
        firstValues.push(item.First)
      })
      setfirstValues(firstValues)
      let followupValues = []
      responce.data.datewise.forEach(item => {
        followupValues.push(item.Followup)
      })
      setfollowupValues(followupValues)
      let specialities = []
      responce.data.specialitywise.forEach(item => {
        specialities.push(item.speciality)
      })
      setspecialities(specialities)
      let count = []
      responce.data.specialitywise.forEach(item => {
        count.push(item.count)
      })
      setcount(count)
    }
    catch (error) {
      console.log(error)
    }

  }

  const getClinics = async () => {
    try {
      const responce = await axiosConfig.get(`/clinics/clinics/`)
      setClinics(responce.data.results)
    }
    catch (error) {
      console.log(error)
    }

  }

  const getServices = async () => {
    try {
      const responce = await axiosConfig.get(`/clinics/doctorservices/`)
      setServices(responce.data.results)
    }
    catch (error) {
      console.log(error)
    }

  }

  const getDoctors = async () => {
    try {
      const responce = await axiosConfig.get(`/clinics/doctors/`)
      setDoctors(responce.data.results)
    }
    catch (error) {
      console.log(error)
    }

  }
  
  useEffect(() => {
    // getUserData();
    
    getClinics();
    getServices();
    getDoctors();
   
  }, [])

  const barChartData = {
    labels: dates,
    datasets: [
      {
        label: 'First',
        data: firstValues,
        backgroundColor: 'rgba(75, 192, 192, 0.6)'
      },
      {
        label: 'Followup',
        data: followupValues,
        backgroundColor: 'rgba(153, 102, 255, 0.6)'
      }
    ]
  };

  const doughnutChartData = {
    labels: specialities,
    datasets: [
      {
        data: count,
        backgroundColor: ['#2b2d42', '#8d99ae']
      }
    ]
  };
  const style = {
    height: '349px !important',
    width: '349px !important'
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name,value)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const HandleSelect = (selectedOption, name) => {
    console.log(selectedOption.value)
    setFormData({ ...formData, [name]: selectedOption.value })
  }
  const handleSubmit = async () => {
    setLoading(true)
    GetData();
  };

  return (
    <>
      <DashboardLayout>
        <div className="mainbar">
          <div className="sidebar-filters px-2">
            <div className="form-group">
              <label>From Date</label>
              <input
                className="form-control"
                type="date"
                name="fromDate"
                value={formData.fromDate}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>To Date</label>
              <input
                className="form-control"
                type="date"
                name="toDate"
                value={formData.toDate}
                onChange={handleChange}
              />
            </div>
            {UserData && UserData.user_type=="staff" && (
            <>
            <div className="form-group">
              <label>Department/Service</label>
              <Select
                options={services && services.map((service) => ({ "value": service.id, "label": service.service_name }))}
                onChange={(selectedOption) => HandleSelect(selectedOption, "service")}
              />
            </div>
            <div className="form-group">
              <label>Doctor</label>
              <Select
                options={doctors && doctors.map((doctor) => ({ "value": doctor.id, "label": doctor.name }))}
                onChange={(selectedOption) => HandleSelect(selectedOption, "doctor")}
              />
            </div>
            </>
            )}
             {UserData && UserData.user_type=="doctor" && (
            <>

           <div className="form-group">
              <label>Clinic/Hospital</label>
              <Select
                options={clinics && clinics.map((clinic) => ({ "value": clinic.id, "label": clinic.clinic_name }))}
                onChange={(selectedOption) => HandleSelect(selectedOption, "clinic")}
              />
            </div>
            <div className="form-group">
              <label>Department/Service</label>
              <Select
                options={services && services.map((service) => ({ "value": service.id, "label": service.service_name }))}
                onChange={(selectedOption) => HandleSelect(selectedOption, "service")}
              />
            </div>
            </>
            )}
             {UserData && UserData.user_type=="admin" && (
            <>

           <div className="form-group">
              <label>Clinic/Hospital</label>
              <Select
                options={clinics && clinics.map((clinic) => ({ "value": clinic.id, "label": clinic.clinic_name }))}
                onChange={(selectedOption) => HandleSelect(selectedOption, "clinic")}
              />
            </div>
            <div className="form-group">
              <label>Department/Service</label>
              <Select
                options={services && services.map((service) => ({ "value": service.id, "label": service.service_name }))}
                onChange={(selectedOption) => HandleSelect(selectedOption, "service")}
              />
            </div>
            <div className="form-group">
              <label>Doctor</label>
              <Select
                options={doctors && doctors.map((doctor) => ({ "value": doctor.id, "label": doctor.name }))}
                onChange={(selectedOption) => HandleSelect(selectedOption, "doctor")}
              />
            </div>
            </>
            )}
            <button className="btn btn-primary btn-filter" onClick={handleSubmit}>
              Filter
            </button>

          </div>
          <div className="main-code">

            <div className='row'>
                <div className='col-lg-6 col-md-12' >
                  <Bar data={barChartData} />
                </div>
                < div className='col-lg-6 col-md-12 d-flex justify-content-center' style={{ height: "349px", width: "349px" }}>
                  <Doughnut data={doughnutChartData} height={null} />
                </div>
            </div>

            <div className="row mt-5">
              <div className='col-md-4'>
                <div className="card" style={{ width: "18rem;" }}>
                  <div className="card-body">
                    <h5 className="card-title">Patients</h5>
                    <p className="card-text">{totalInfo && totalInfo.patients}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="card" style={{ width: "18rem;" }}>
                  <div className="card-body">
                    <h5 className="card-title">Income</h5>
                    <p className="card-text">{totalInfo && totalInfo.income}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="card" style={{ width: "18rem;" }}>
                  <div className="card-body">
                    <h5 className="card-title">Procedures</h5>
                    <p className="card-text">{totalInfo && totalInfo.Procedure}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout >
    </>
  )


};

export default ChartTable;
