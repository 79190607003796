import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostsPageGrid } from "./PostsPageGrid";
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig";
import Modal from 'react-bootstrap/Modal';
import { EditProfile, EditDp, DisplayBio, MyProjects, DashboardLayout } from "../Components";
import { MdOutlineModeEditOutline, MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { View24Crafts } from "./View24Crafts";
import { Verified } from "./Verified";
import { FormParser } from "../Components";
import Masters from '../Components/masters.json'
import { PopupModal } from "../Components";
const ProfilePage = () => {
    let { id } = useParams();
    let hcc_id = id
    let can_edit_profile = true
    const [EditProfileFor, setEditProfileFor] = useState("profile");
    const [EditProfileShow, setEditProfileShow] = useState(false);
    const [Data, SetData] = useState({});
    const [readOnly, setReadOnly] = useState(true);
    const handleEditProfileClose = () => setEditProfileShow(false);
    const [Message, setMessage] = useState("")
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false)

    const [ProfileData, SetProfileData] = useState({})

    function handleLoginEvent() {
        setEditProfileShow(false);
        GetData()
    }
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/user-profiles/${id}/?get_all_users=1`)
            console.log(responce)
            SetProfileData(responce.data)
            hcc_id = responce.data.hcc_id
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)

        }
    }
    const GetVerifiedData = () => {
        setLoading(true)
        axiosConfig.get(`/accounts/verified-profile/?profile=${id}`).then(res => {
            console.log(res.data.results)
            if(res.data.results.length>0){
                SetData(res.data.results[0])
            }
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }
    const formSubmit = (formData) => {
        console.log(Data, "data", formData)
        delete formData.govt_id_proof_front_copy;
        delete formData.govt_id_proof_back_copy;
        axiosConfig.patch(`/accounts/verified-profile/${Data.id}/`, formData).then(res => {
            console.log(res)
            GetVerifiedData()
            setMessage("Verified Updated SuccessFully!")
            setpopupType("success");
        }).catch(error => {
            console.log(error.response)
        })
    };
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    useEffect(() => {
        GetData();
        GetVerifiedData()
    }, [])
    const calculateAge = (dob) => {
        const dobDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
        }
        return age;
    };
    return (
        <>
            {/* <Topbar /> */}
            <DashboardLayout >
                <div className="main-container">
                    <div className="content-area">
                        {loading ? (
                            <div className="loader-container">
                                <img src={require('./images/loader.gif')} alt="Loading..." />
                            </div>
                        ) : (
                            <div>

                                <div className="profile-header">
                                    <div className="row">
                                        <div className="col-md-4">
                                            {can_edit_profile ?
                                                <div className="editabledp">
                                                    <img src={ProfileData.profile_pic} className="dp" />
                                                    <div class="middle">
                                                        <div class="text" onClick={() => {
                                                            setEditProfileShow(true);
                                                            setEditProfileFor("dp");
                                                        }}>
                                                            <MdOutlineModeEditOutline size={30} />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <img src={ProfileData.profile_pic} className="dp" />
                                            }
                                        </div>
                                        <div className="col-md-8">
                                            <div className="profile-info">
                                                <p>{ProfileData.full_name} {can_edit_profile && <button className="btn btn-default" onClick={() => {
                                                    setEditProfileShow(true);
                                                    setEditProfileFor("profile");
                                                }} ><MdOutlineModeEditOutline /></button>} </p>
                                                <button className="btn btn-default btn-follow">Follow</button>
                                                <button className="btn btn-default btn-message">Message</button>
                                            </div>
                                            <div className="followers-info">
                                                <p>{ProfileData.designation}</p>
                                                <p><b>10</b> Gallery</p>
                                                <p><b>{ProfileData.followers}</b> Followers</p>
                                            </div>
                                            <div className="details">
                                                <table className="table table-bordered">
                                                    <tr>
                                                        <th>HCCID</th>
                                                        <td>{ProfileData.hcc_id}</td>
                                                        <th>Full Name</th>
                                                        <td>{ProfileData.full_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Mobile</th>
                                                        <td>{ProfileData.mobile_number}</td>
                                                        <th>Email</th>
                                                        <td>{ProfileData.user && ProfileData.user.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Gender</th>
                                                        <td>{ProfileData.gender}</td>
                                                        <th>Profession</th>
                                                        <td>{ProfileData.profession}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Dob</th>
                                                        <td>
                                                            {ProfileData.dob &&
                                                                <>
                                                                    {ProfileData.dob} ({calculateAge(ProfileData.dob)} years)
                                                                </>
                                                            }
                                                        </td>
                                                        <th>Roles</th>
                                                        <td>
                                                            {
                                                                ProfileData.roles && ProfileData.roles.map((role, i) => {
                                                                    return (
                                                                        <>{role.role_name}, </>
                                                                    )
                                                                })
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Feedback</th>
                                                        <td>{ProfileData.feedback}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {hcc_id ?
                                    <>
                                        <div className="profile-tablinks">
                                            <ul className="nav custom-nav-tabs">
                                                <li className="nav-item">
                                                    <button
                                                        className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                                                        onClick={() => handleTabClick(0)}
                                                    >
                                                        GALLERY
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button
                                                        className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                                                        onClick={() => handleTabClick(1)}
                                                    >
                                                        ABOUT
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button
                                                        className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                                                        onClick={() => handleTabClick(2)}
                                                    >
                                                        PROJECTS
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button
                                                        className={`nav-link ${activeTab === 3 ? 'active' : ''}`}
                                                        onClick={() => handleTabClick(3)}
                                                    >
                                                        24 Crafts
                                                    </button>
                                                    </li>
                                              {ProfileData?.is_verified&&<li className="nav-item">
                                                    <button
                                                        className={`nav-link ${activeTab === 4 ? 'active' : ''}`}
                                                        onClick={() => handleTabClick(4)}
                                                    >
                                                      Verified
                                                    </button>
                                                </li>}
                                            </ul>
                                        </div>
                                        <div className="tab-content">
                                            <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`}>
                                                <div className="posting-container">
                                                    <PostsPageGrid profile_id={ProfileData.id} />
                                                </div>
                                            </div>
                                            <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`}>
                                                {activeTab === 1 &&
                                                    <div className="display-bio">
                                                        <DisplayBio ProfileData={ProfileData} can_edit_profile={can_edit_profile} />
                                                    </div>
                                                }
                                            </div>
                                            <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`}>
                                                <div className="projects-container">
                                                    {activeTab === 2 &&
                                                        <div className="display-projects">
                                                            <MyProjects ProfileData={ProfileData} can_edit_profile={can_edit_profile} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`}>
                                                <div className="projects-container">
                                                    {activeTab === 3 &&
                                                        <div className="display-projects">
                                                            <View24Crafts ProfileData={ProfileData} can_edit_profile={can_edit_profile} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className={`tab-pane fade ${activeTab === 4 ? 'show active' : ''}`}>
                                                <div className="projects-container">
                                                    {activeTab === 4 &&
                                                        <div className="display-projects">
                                                            <FormParser modelObject={Masters.Verified} formData={Data} formSubmit={formSubmit} error={Error} readOnly={readOnly} setReadOnly={setReadOnly} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="no-profile">
                                            <NavLink to="/24crafts">
                                                <button className="btn btn-default">Click Here to Create Profile</button>
                                            </NavLink>
                                        </div>
                                    </>
                                }
                                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={EditProfileShow} onHide={handleEditProfileClose} className="edit-profile-popup">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit Profile</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <>
                                            {EditProfileFor === "dp" ?
                                                <EditDp ProfileData={ProfileData} handleLoginEvent={handleLoginEvent} />
                                                :
                                                <EditProfile ProfileData={ProfileData} handleLoginEvent={handleLoginEvent} />
                                            }
                                        </>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        )}
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message={Message} />
                }
            </DashboardLayout >
        </>
    )
}
export { ProfilePage }