import React, { useState } from 'react';
import axiosConfig from "../Service/axiosConfig"


const AddUser = ({handleLoginEvent}) => {
  const [Data, setData] = useState({})
  const [Loading, setLoading] = useState(false)
  const [error, setError] = useState(null);
  const OnSubmit = (e) => {
    e.preventDefault()
    setError(null);
    setLoading(true);

    // Handle signup logic here
    console.log('Signup button clicked!');
    axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data'; 
        axiosConfig.post("/accounts/api/signup/", Data).then(res => {
            console.log(res)
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('profile_data', JSON.stringify(res.data.profile_data));
            handleLoginEvent()
            setLoading(false);
        }).catch(error => {
            console.log(error)
            if(error.response){
              setError(error.response.data);
            }else{
              setError("Error! Make sure to upload the Profile Picture less than 1MB");
            }
            setLoading(false);
        })

        
  };



  const handleInputChange = (e) => {
      e.preventDefault();
      const name = e.target.name
      const value = e.target.value
      console.log(name, value)
      setData({ ...Data, [name]: value })
  }
  const HandleFiles = (e) => {
      e.preventDefault()
      const file = e.target.files[0];
      const name = e.target.name;
      const value = file;
      console.log(name, value);
      setData({ ...Data, [name]: value })
  }
  return (
    <div className="container">
      <form onSubmit={OnSubmit} >
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              className="form-control"
              required
              name="first_name"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              className="form-control"
              required
              name="last_name"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label>Email:</label>
            <input
              type="text"
              className="form-control"
              required
              name="email"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>Mobile:</label>
            <input
              type="text"
              className="form-control"
              required
              name="mobile"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              className="form-control"
              required
              name="password"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>Profession:</label>
            <input
              type="text"
              className="form-control"
              name="profession"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label>Gender:</label>
            <select 
              className="form-control"
              required
              name="gender"
              onChange={handleInputChange}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
            </select>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>Date of Birth:</label>
            <input
              type="date"
              className="form-control"
              name="dob"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <div className="form-group">
          <label>Profile Picture:</label>
          <input
            type="file"
            accept="image/*"
            className="form-control"
            required
            name="profilePic"
            onChange={HandleFiles}
          />
        </div>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      <button type="submit" className="btn btn-primary btn-block" disabled={Loading}>
        Signup
      </button>
      </form>
    </div>
  );
};

export {AddUser};
