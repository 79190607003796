import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { useNavigate } from 'react-router-dom';
import {PostGrid,PopupModal} from "../Components";
import Masonry from 'react-masonry-css'
import { MdKeyboardArrowLeft,MdOutlineNavigateNext,MdOutlineNavigateBefore,MdOutlineModeEditOutline,MdDeleteOutline  } from "react-icons/md";

import Modal from 'react-bootstrap/Modal';
import { PostPop } from "../Components/Post/PostPop";

const PostsPageGrid = (props) => {
    const [photoShow, setphotoShow] = useState(false);
    const [item, setItem] = useState();
    const [popUpIdx, setpopUpIdx] = useState();

    let {profile_id} = props;
    console.log(profile_id)
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [Data, SetData] = useState([])
    const [popupType, setpopupType] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };

    let page = 1;
    let totalPages;

    const GetData = async () => {
        setLoading(true)
        try {
            console.log('profile_id',profile_id)
            const responce = await axiosConfig.get(`/accounts/model-images/?profile=${profile_id}&is_active=true`)
            totalPages = Math.ceil(responce.data.count / 20);
            console.log(totalPages)
            SetData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const [ModelShow, setModelShow] = useState(false);
    const handleEditProfileClose = () => {
        setModelShow(false);
    }

    const [SuccessMessage, setSuccessMessage] = useState("");

    const successEvnet = (data) => {
        setModelShow(false)
        GetData();
        setpopupType("")
    }


    const [images, setImages] = useState([]);
    const [caption, setCaption] = useState('');
  
    const handleImageChange = (e) => {
      const files = Array.from(e.target.files);
      setImages(files);
    };
  
    const DeleteProject = (id) => {
        axiosConfig.delete(`/accounts/model-images/${id}/`).then(res => {
            console.log(res)
            setSuccessMessage("Photo Deleted Successfully !")
            setpopupType("success")
            setModelShow(false);
            GetData()
        }).catch(error => {
            let errorData={}
            console.log(errorData)
            setpopupType("error")
        })
    }
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const formData = new FormData();
      formData.append('caption', caption);
  
      images.forEach((photo) => {
        formData.append("images", photo);
      });
      try {

        const response = await axiosConfig.post(`/accounts/model-images/create_with_images/?profile_id=${profile_id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        console.log('API response:', response.data);
        setpopupType("success")
        setModelShow(false)
        GetData();
      } catch (error) {
        console.error('Error submitting form:', error);
        setpopupType("error")
      }
    };

    const handleInfiniteScroll = async () => {

        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 500 >=
                document.documentElement.scrollHeight
            ) {
                if (page < totalPages) {
                    setLoading2(true)
                    page = page + 1
                    let resp = await axiosConfig.get(`/accounts/model-images/?is_story=true&is_active=true&page=${page}`)
                    SetData((Data) => [...Data, ...resp.data.results])
                    setLoading2(false)
                }else{
                    setLoading2(false)
                }
                
            }

        } catch (error) {
            console.log(error)
        }
    }

    function handleHidePostEvent() {
        GetData();
    }

    function handleClickEvent(clickevent) {
        console.log(clickevent)
        if(clickevent == "next"){
            let newitem = Data[popUpIdx+1]
            if (newitem){
                setItem(newitem)
                setpopUpIdx(popUpIdx+1)
            }
        }else{
            let newitem = Data[popUpIdx-1]
            if (newitem){
                setItem(newitem)
                setpopUpIdx(popUpIdx-1)
            }
        }
        console.log(Data.length)
    }

    useEffect(() => {
        GetData();
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        }
    }, [])
                             
    return (
        <>
            {
                    <div className="products-container">
                        {loading ? (
                            <div className="loader-container">
                                <img src={require('./images/loader.gif')} alt="Loading..." />
                            </div>
                        ) : (
                            <>
                                {Data.length > 0 &&
                                    <div className="post-data post-grid">
                                        <Masonry
                                            breakpointCols={3}
                                            className="my-masonry-grid"
                                            columnClassName="my-masonry-grid_column">
                                                {
                                            Data.map((itemObj, index) => {
                                                return (
                                                    <div className="post-item" key={index}>
                                                        <div className="post-inner-container">
                                                            <div className="post-image" onClick={() => {setphotoShow(true);setItem(itemObj);setpopUpIdx(index)} }  >
                                                                <img src={itemObj.image} className="post-img"/>
                                                                <button className="btn btn-default" onClick={(e)=>{ DeleteProject(itemObj.id) }}><MdDeleteOutline /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </Masonry>

                                        
                                        {loading2 && (
                                            <div className="loader-container">
                                                <img src={require('./images/loader.gif')} alt="Loading..." />
                                            </div>
                                        )}
                                    </div>
                                }
                            </>
                        )}
                    </div>
            }

<button className="btn btn-primary" onClick={(e => setModelShow(true))}>Upload Gallery</button>


                        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={ModelShow} onHide={handleEditProfileClose} className="edit-profile-popup">
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                            <>
                                                Add Photos
                                            </>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <>
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label htmlFor="images" className="form-label">Images:</label>
                                                <input
                                                type="file"
                                                className="form-control"
                                                id="images"
                                                accept="image/*"
                                                multiple
                                                onChange={handleImageChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="caption" className="form-label">Caption:</label>
                                                <textarea
                                                className="form-control"
                                                id="caption"
                                                value={caption}
                                                onChange={(e) => setCaption(e.target.value)}
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </form>
                                    </>
                                </Modal.Body>
                            </Modal>

                            {popupType != "" &&
                                <>
                                    {SuccessMessage ? 
                                        <PopupModal popupType={popupType} successEvnet={successEvnet} Message={SuccessMessage}/>
                                        :
                                        <PopupModal popupType={popupType} successEvnet={successEvnet}/>
                                    }
                                </>
                            }
                            
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={photoShow} onHide={() =>setphotoShow(false) } className="viewphoto-popup">
                <Modal.Header className="show-only-mobile">
                    <div className="model-header-wid">
                        <div className="model-header-popup">
                            <div className="model-popup-back">
                                <MdKeyboardArrowLeft onClick={() =>setphotoShow(false) } size={30}/>
                            </div>
                            <p className="posttitle">Post</p>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <PostPop item={item} handleHideEvent={handleHidePostEvent} />
                    <div className="navigation-buttons">
                        <MdOutlineNavigateBefore className="nav-prev" onClick={() => handleClickEvent("prev")} />
                        <MdOutlineNavigateNext className="nav-next" onClick={() => handleClickEvent("next")}  />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export { PostsPageGrid };