import React, { useState, useEffect } from "react";
import "./DisplayBio.css";
import axiosConfig from "../../Service/axiosConfig";
import { FormParser, PopupModal } from "../";
import Modal from 'react-bootstrap/Modal';
import Masters from "../masters.json";

const DisplayBio = ({ ProfileData, can_edit_profile }) => {
    const [Data, SetData] = useState([]);
    const [Data2, SetData2] = useState([]);
    const [EditFormData, SetEditFormData] = useState([]);
    const [Fmset, SetFmset] = useState('ProfileSet1');
    const [DataModel, SetDataModel] = useState(Masters.ProfileSet1);
    const [EditModelShow, setEditModelShow] = useState(false);
    const [Error, SetError] = useState("");
    const [formerrorData, SetformErrorData] = useState({});
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false);
    const getModalSequence = () => {
        let sequence = [
            'ProfileSet1',
            'ProfileSet2View',
            'ProfileSet2ProjectTypes',
            'ProfileSet2Categories',
            'ProfileSet2Skills'
        ];

        if (ProfileData.gender === "female") {
            sequence.push('ProfileSet2DressComforts');
        }
        return sequence;
    };

    const [modalSequence, setModalSequence] = useState(getModalSequence());
    const [modalHeaders] = useState([
        'Edit Basic Details',
        'Edit Personal Details',
        'Edit Project Types',
        'Edit Categories',
        'Edit Skills',
        'Edit Dress Comforts'
    ]);

    const [currentStep, setCurrentStep] = useState(0);

    // const GetData = async () => {
    //     setLoading(true);
    //     try {
    //         const response = await axiosConfig.get(`/accounts/api/about/${ProfileData.hcc_id}/`);
    //         let profile_set_1 = response.data.profile_set_1;
    //         profile_set_1['name'] = ProfileData.full_name;
    //         profile_set_1['email'] = "hyderabadcastingclub@gmail.com";
    //         profile_set_1['mobile'] = "+91 7799234563";
    //         profile_set_1['altmobile'] = "+91 8712345549";
    //         let pl = [];
    //         profile_set_1['languages'].forEach(language => (
    //             pl.push(language.id)
    //         ));
    //         profile_set_1['languages'] = pl;
    //         if (profile_set_1['permanent_city']) {
    //             profile_set_1['permanent_city'] = profile_set_1['permanent_city']['id'];
    //         }
    //         if (profile_set_1['present_city']) {
    //             profile_set_1['present_city'] = profile_set_1['present_city']['id'];
    //         }
    //         if (profile_set_1['permanent_state']) {
    //             profile_set_1['permanent_state'] = profile_set_1['permanent_state']['id'];
    //         }
    //         if (profile_set_1['present_state']) {
    //             profile_set_1['present_state'] = profile_set_1['present_state']['id'];
    //         }
    //         SetData(profile_set_1);
    //         SetData2(response.data.profile_set_2);
    //         setLoading(false);
    //     } catch (error) {
    //         console.log(error);
    //         setLoading(false);
    //     }
    // };
    const GetData = async () => {
        setLoading(true)

        try {
            const responce = await axiosConfig.get(`/accounts/api/about/${ProfileData.id}/`)
            console.log(responce)
            let profile_set_1 = responce.data.profile_set_1
            profile_set_1['name'] = ProfileData.full_name
            let pl = []
            profile_set_1['languages'].map(language => (
                pl.push(language.id)
            ))
            profile_set_1['languages'] = pl
            if (profile_set_1['permanent_city']) {
                profile_set_1['permanent_city'] = profile_set_1['permanent_city']['id']
            }
            if (profile_set_1['present_city']) {
                profile_set_1['present_city'] = profile_set_1['present_city']['id']
            }
            if (profile_set_1['permanent_state']) {
                profile_set_1['permanent_state'] = profile_set_1['permanent_state']['id']
            }
            if (profile_set_1['present_state']) {
                profile_set_1['present_state'] = profile_set_1['present_state']['id']
            }
            SetData(profile_set_1)
            SetData2(responce.data.profile_set_2)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const formSubmit = (FormData) => {
        let url = `/accounts/${Fmset}/${Data.id}/`;
        if (Fmset !== "ProfileSet1") {
            url = `/accounts/${Fmset}/${Data2.id}/`;
        }
        axiosConfig.patch(url, FormData).then(res => {
            if (currentStep < modalSequence.length - 1) {
                setCurrentStep(currentStep + 1);
                openNextModal(currentStep + 1);
            } else {
                setpopupType("success");
                setEditModelShow(false);
                setCurrentStep(0);
                GetData()
            }

        }).catch(error => {
            let errorData = {};
            console.log(errorData);
            SetformErrorData(errorData);
            setpopupType("error");
        });
    };

    const openNextModal = (step) => {
        setEditModelShow(true);
        const stepConfig = {
            ProfileSet1: Masters.ProfileSet1,
            ProfileSet2View: ProfileData.gender === "female" ? Masters.ProfileSet2ViewFemale : Masters.ProfileSet2ViewMale,
            ProfileSet2ProjectTypes: Masters.ProfileSet2ProjectTypes,
            ProfileSet2Categories: ProfileData.gender === "female" ? Masters.ProfileSet2CategoriesFemale : Masters.ProfileSet2CategoriesMale,
            ProfileSet2Skills: Masters.ProfileSet2Skills,
            ProfileSet2DressComforts: Masters.ProfileSet2DressComforts
        };

        if (modalSequence[step] === 'ProfileSet1') {
            SetEditFormData(Data);
        } else {
            SetEditFormData(Data2);
        }

        SetDataModel(stepConfig[modalSequence[step]]);
        SetFmset(modalSequence[step] === 'ProfileSet1' ? 'ProfileSet1' : 'ProfileSet2');
    };

    const handleEditProfileClose = () => {
        setEditModelShow(false);
        setCurrentStep(0);
    };

    useEffect(() => {
        GetData();
    }, []);

    useEffect(() => {
        setModalSequence(getModalSequence());
    }, [ProfileData.gender]);

    return (
        <>
            {loading ? (
                <div className="loader-container">
                    <img src={require('./images/loader.gif')} alt="Loading..." />
                </div>
            ) : (
                <div className="container">
                    <div className="d-flex" style={{ justifyContent: "flex-start", alignItems: 'center', gap: '20px' }}>
                        <h6>Basic details:</h6>
                        {can_edit_profile && <span style={{ paddingBottom: '10px' }} onClick={() => { setCurrentStep(0); openNextModal(0); }} >Edit</span>}
                    </div>
                    <FormParser modelObject={Masters.ProfileSet1View} formData={Data} readonly={true} />
                    {Object.keys(Data2).length > 0 &&
                        <div className="profile-details2">
                            {/* Personal details section */}
                            <div className="d-flex" style={{ justifyContent: "flex-start", alignItems: 'center', gap: '20px' }}>
                                <h6>Personal details:</h6>
                                {can_edit_profile && <span style={{ paddingBottom: '10px' }} onClick={() => { setCurrentStep(1); openNextModal(1); }} >Edit</span>}
                            </div>
                            {ProfileData.gender === "female" ?
                                <FormParser modelObject={Masters.ProfileSet2ViewFemale} formData={Data2} readonly={true} />
                                :
                                <FormParser modelObject={Masters.ProfileSet2ViewMale} formData={Data2} readonly={true} />
                            }

                            {/* Project Types section */}
                            <div className="d-flex" style={{ justifyContent: "flex-start", alignItems: 'center', gap: '20px' }}>
                                <h6>Project Types:</h6>
                                {can_edit_profile && <span style={{ paddingBottom: '10px' }} onClick={() => { setCurrentStep(2); openNextModal(2); }} >Edit</span>}
                            </div>
                            <FormParser modelObject={Masters.ProfileSet2ProjectTypes} formData={Data2} readonly={true} />

                            {/* Categories section */}
                            <div className="d-flex" style={{ justifyContent: "flex-start", alignItems: 'center', gap: '20px' }}>
                                <h6>Categories:</h6>
                                {can_edit_profile && <span style={{ paddingBottom: '10px' }} onClick={() => { setCurrentStep(3); openNextModal(3); }} >Edit</span>}
                            </div>
                            {ProfileData.gender === "female" ?
                                <FormParser modelObject={Masters.ProfileSet2CategoriesFemale} formData={Data2} readonly={true} />
                                :
                                <FormParser modelObject={Masters.ProfileSet2CategoriesMale} formData={Data2} readonly={true} />
                            }

                            {/* Skills section */}
                            <div className="d-flex" style={{ justifyContent: "flex-start", alignItems: 'center', gap: '20px' }}>
                                <h6>Skills:</h6>
                                {can_edit_profile && <span style={{ paddingBottom: '10px' }} onClick={() => { setCurrentStep(4); openNextModal(4); }} >Edit</span>}
                            </div>
                            <FormParser modelObject={Masters.ProfileSet2Skills} formData={Data2} readonly={true} />

                            {/* Dress Comforts section */}
                            {ProfileData.gender === "female" &&
                                <>
                                    <div className="d-flex" style={{ justifyContent: "flex-start", alignItems: 'center', gap: '20px' }}>
                                        <h6>Dress Comforts:</h6>
                                        {can_edit_profile && <span style={{ paddingBottom: '10px' }} onClick={() => { setCurrentStep(modalSequence.indexOf('ProfileSet2DressComforts')); openNextModal(modalSequence.indexOf('ProfileSet2DressComforts')); }} >Edit</span>}
                                    </div>
                                    <FormParser modelObject={Masters.ProfileSet2DressComforts} formData={Data2} readonly={true} />
                                </>
                            }
                        </div>
                    }
                </div>
            )}
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={EditModelShow} onHide={handleEditProfileClose} className="edit-profile-popup">
                <Modal.Header closeButton>
                    <Modal.Title>{modalHeaders[currentStep]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser
                        modelObject={DataModel}
                        formData={EditFormData}
                        formSubmit={formSubmit}
                        error={Error}
                        buttonTitle={currentStep === modalSequence.length - 1 ? "Submit" : "Save and Next"}
                    // buttonTitle="Save and Next"
                    />
                </Modal.Body>
            </Modal>
            {popupType !== "" &&
                <PopupModal popupType={popupType} successEvnet={() => { GetData(); setpopupType(""); }} />
            }
        </>
    );
}

export { DisplayBio };
