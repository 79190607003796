import React from "react";
import { Modal } from "react-bootstrap";

const PreviewModal = ({handleClose,show,selectedImage}) => {
    return (
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName="images-display"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {selectedImage && (
                        <img
                            className="image-popup"
                            src={selectedImage.image}
                            alt={`Image ${selectedImage.id}`}
                            style={{
                                width: "auto",         // Maintain the aspect ratio by setting width to "auto"
                                height: "500px",       // Set a fixed height for the image
                                display: "block",      // Ensure the image is displayed as a block element
                                margin: "0 auto"  ,
                                maxWidth:"100%"     // Center the image horizontally
                            }}
                        />
                    )}
                </Modal.Body>
            </Modal>
    )
}
export default PreviewModal