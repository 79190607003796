import React, { useState, useEffect } from "react";
import "./Post.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink } from "react-router-dom";
import { MdOutlineMoreHoriz } from "react-icons/md";
import {AiOutlineHeart,AiOutlineComment,AiOutlineSend,AiOutlineStar} from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import { Actions } from "./Actions";
import { Helmet } from "react-helmet";


const PostPop = (props) => {

    const { item } = props;
    console.log("pitem")
    console.log(item)
    function handleHideEvent() {
        props.handleHideEvent();
    }
    
    return (
        <>
                    <Helmet>
                        <meta property="og:image" content={item.image} />
                        <meta property="og:title" content={item.profile.full_name}  />
                        <meta property="og:description" content={`${item.profile.hcc_id} - ${item.profile.designation}`} />
                    </Helmet>
                    <div className="post-modal-view">
                        <div className="pm-img-container">
                            <img src={item.image} className="post-modal-img"/>
                        </div>
                        <div className="pm-content-container">
                            <div className="pdpop-header">
                                <div className="pdpop-ls">
                                    <div className="pdpop-ls-container">
                                        <img src={item.profile.profile_pic} className="post-dp" width="40" /> 
                                        <div className="pdpop-titles">
                                            <p>{item.profile.full_name}</p>
                                            <p>{item.profile.designation}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pdpop-rs">
                                    <Actions item={item} handleHideEvent={handleHideEvent} />
                                </div>
                            </div>
                            <hr className="pdpop-hr" />
                        </div>
                    </div>
        </>
    )
}
export { PostPop };