import React, { useState, useEffect } from 'react';
import axiosConfig from "../../Service/axiosConfig"
import Select from 'react-select';

const EditProfile = ({ProfileData,handleLoginEvent}) => {
  const roles_list = ProfileData.roles.map(role => role.id);
  const [Data, setData] = useState({
    profile_id: ProfileData.id,
    roles: roles_list, // Initialize with an empty array
  });
  const [roles, setRoles] = useState([]);
  const OnSubmit = (e) => {
    e.preventDefault()

    // Handle signup logic here
    console.log('Signup button clicked!');
    axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data'; 
        axiosConfig.post("/accounts/api/editprofile/", Data).then(res => {
            console.log(res)
            handleLoginEvent()
        }).catch(error => {
            console.log(error.response.data.error)
        })

        
  };

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    // Fetch roles from the API
    axiosConfig.get('/masters/roles/')
      .then(response => {
        setRoles(response.data.results); // Assuming the API response contains an array of roles
        setLoading(false)

      })
      .catch(error => {
        console.error('Error fetching roles:', error);
        setLoading(false)
      });
  }, []);

  const handleRoleChange = (selectedOptions) => {
    // Map the selected options to an array of role IDs
    const selectedRoleIds = selectedOptions.map(option => option.value);
    setData(prevData => ({ ...prevData, roles: selectedRoleIds }));
  };
  

  const handleInputChange = (e) => {
      e.preventDefault();
      const name = e.target.name
      const value = e.target.value
      console.log(name, value)
      setData({ ...Data, [name]: value })
  }
  const HandleFiles = (e) => {
      e.preventDefault()
      const file = e.target.files[0];
      const name = e.target.name;
      const value = file;
      console.log(name, value);
      setData({ ...Data, [name]: value })
  }
  return (
    <>
    {loading ? (
      <div className="loader-container">
          <img src={require('./images/loader.gif')} alt="Loading..." />
      </div>
  ) : (
    <div className="container">
      <form onSubmit={OnSubmit} >
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              className="form-control"
              required
              name="first_name"
              onChange={handleInputChange}
              defaultValue={ProfileData.user.first_name}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              className="form-control"
              required
              name="last_name"
              onChange={handleInputChange}
              defaultValue={ProfileData.user.last_name}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label>Email:</label>
            <input
              type="text"
              className="form-control"
              required
              name="email"
              onChange={handleInputChange}
              defaultValue={ProfileData.user.email}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>Mobile:</label>
            <input
              type="text"
              className="form-control"
              required
              name="mobile"
              onChange={handleInputChange}
              defaultValue={ProfileData.mobile_number}
            />
          </div>
        </div>
      </div>
      
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label>Gender:</label>
            <select 
              className="form-control"
              required
              name="gender"
              onChange={handleInputChange}
              >
                <option value="male">Male</option>
                <option value="female" selected={ProfileData.gender === "female"} >Female</option>
                <option value="other" selected={ProfileData.gender === "other"}>Other</option>
            </select>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>Profession:</label>
            <input
              type="text"
              className="form-control"
              name="profession"
              onChange={handleInputChange}
              defaultValue={ProfileData.profession}

            />
          </div>
        </div>
        <div className="col-sm-6">
            <div className="form-group">
              <label>Roles:</label>
                <Select
                  options={roles.map(role => ({ label: role.role_name, value: role.id }))}
                  isMulti
                  onChange={handleRoleChange}
                  defaultValue={roles.map(role => ({ label: role.role_name, value: role.id })).filter(role => Data.roles.includes(role.value))}
                />
            </div>
          </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>Dob:</label>
            <input
              type="date"
              className="form-control"
              name="dob"
              onChange={handleInputChange}
              defaultValue={ProfileData.dob}

            />
          </div>
          </div>
          <div className="col-sm-12">
          <div className="form-group">
            <label>Feedback:</label>
            <textarea
              type="text"
              className="form-control"
              name="feedback"
              onChange={handleInputChange}
            >{ProfileData.feedback}</textarea>
          </div>
        </div>
      </div>
      <button type="submit" className="btn btn-primary btn-block">
        Update Profile
      </button>
      </form>
    </div>
    )}
    </>
  );
};

export {EditProfile};
