import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json"
import { MdOutlinePhone } from "react-icons/md"
import axiosConfig from "../Service/axiosConfig"
import { useParams, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AuditionRequirements } from "./AudiRequirements";
import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";
import ProfessionalProofs from "./ProfessionalProofs";
import PersonalProofs from "./PersonalProofs";
const VerifiedInfo = ({ Code }) => {
    let { id } = useParams();
    console.log(id, "iddddd")
    const navigate = useNavigate();
    const [key, setKey] = useState('Basic Info');
    const [readOnly, setReadOnly] = useState(true);
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [Data, SetData] = useState({});
    const [ApplicationData, SetApplicationData] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(30);
    const [Message, setMessage] = useState("")
    const GetData = () => {
        setLoading(true)
        axiosConfig.get(`/accounts/verified-profile/${id}/`).then(res => {
            console.log(res.data)
            SetData(res.data)
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }
    const formSubmit = (formData) => {
        console.log(Data, "data", formData)
        delete formData.govt_id_proof_front_copy;
        delete formData.govt_id_proof_back_copy;
        axiosConfig.patch(`/accounts/verified-profile/${Data.id}/`, formData).then(res => {
            console.log(res)
            GetData()
            setMessage("Verified Updated SuccessFully!")
            setpopupType("success");
        }).catch(error => {
            console.log(error.response)
        })
    };
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const calculateAge = (dob) => {
        const dobDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
        }
        return age;
    };
    const handleNavigate = () => {
        navigate(`/dashboard/masters/verified`)
    }
    useEffect(() => {
        GetData()
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="client-options">
                    <BiArrowBack onClick={() => handleNavigate()} className="option-icon" />
                </div>
                <div className="client-info">
                    <div className="contact-detile">
                        Name : <div className="data-text">{`${Data.surname && Data.surname} ${Data.name}`}</div>
                    </div>
                    <div className="contact-detile">
                        Email : <div className="data-text">{Data.email}</div>
                    </div>
                    <div className="contact-detile">
                        Status : <div className="data-text">{Data.status}</div>
                    </div>
                    <div className="contact-detile">
                        Contact Mobile No : <a href={`tel:+${Data.mobile_number}`} className="data-text">{Data.mobile_number}</a>
                    </div>
                </div>
                <div className="client-form">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    // className="mb-3"
                    >
                        <Tab eventKey="Basic Info" title="Basic Info">
                            <FormParser modelObject={Masters.Verification} formData={Data} formSubmit={formSubmit} error={Error} readOnly={readOnly} setReadOnly={setReadOnly} />
                        </Tab>
                        <Tab eventKey="Status" title="Status">
                            <FormParser modelObject={Masters.Verified} formData={Data} formSubmit={formSubmit} error={Error} readOnly={readOnly} setReadOnly={setReadOnly} />
                        </Tab>
                        <Tab eventKey="professional proofs" title="professional proofs">
                            {key === "professional proofs" && <ProfessionalProofs />}
                        </Tab>
                        <Tab eventKey="personal proofs" title="personal proofs">
                            <PersonalProofs Data={Data} />
                        </Tab>
                    </Tabs>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message={Message} error={error} />
                }
            </DashboardLayout >
        </>
    )

}
export { VerifiedInfo }