import React, { useEffect, useState, useRef } from "react";
import { AiOutlineSetting } from 'react-icons/ai';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { RiBillFill } from 'react-icons/ri';
import { MdOutlineColorLens } from 'react-icons/md';
import { MdOutlineDashboard, MdManageAccounts, MdCalendarMonth, MdList, } from "react-icons/md"
import { FaUsers } from "react-icons/fa";
import { TbReport, TbReportSearch } from 'react-icons/tb';
import { BiBarChartSquare, BiStore } from "react-icons/bi"
import { MdCircleNotifications } from "react-icons/md"
import { RiLogoutCircleRLine } from "react-icons/ri"
import { GoBell } from 'react-icons/go';
import { FaCommentMedical, FaClinicMedical } from "react-icons/fa"
import { FaUserMd } from "react-icons/fa"
import "./DashboardLayout.css"
import sidebar from "./sidebar";
import { ChildNav } from "./ChildNav";
import { useNavigate } from "react-router";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ChartTable from "./Chart"
import { CgMenuBoxed, CgProfile } from "react-icons/cg"
import { NavLink } from "react-router-dom";
const DashboardLayout = ({ children }) => {
    const Navigate = useNavigate();
    const [smShow, setSmShow] = useState(false);
    const [childData, setChildData] = useState([])
    const [Toggle, SeToggle] = useState(true)
    const [ParentNav, SetParentNav] = useState("")
    const [ChildNavs, SetChildNavs] = useState("")
    const [UserData, SetUserData] = useState({})
    const scrollableContainerRef = useRef(null);
    const handleNav = (event, url) => {
        let parentnav = event.currentTarget.dataset.parentnav
        localStorage.setItem('ParentNav', parentnav);
        localStorage.setItem("url", url)
        if (parentnav === "masters" || parentnav === "accounts") {
            setChildData(sidebar[parentnav])
            SetParentNav(parentnav)
        }
    }
    const AlertLogout = () => {
        setSmShow(!smShow)
    }
    const handleLogout = (event) => {
        localStorage.removeItem('token');
        setSmShow(false)
        Navigate("/")
    }
    const getUserData = async () => {
        const user_data = JSON.parse(localStorage.getItem('profile_data'));
        SetUserData(user_data);
    }
    const getParentNav = async () => {
        let parentnav = localStorage.getItem('ParentNav')
        let childnavpath = localStorage.getItem('ChildNavs')
        if (parentnav === "masters" || parentnav === "accounts") {
            SetChildNavs(childnavpath)
            setChildData(sidebar[parentnav])
        }
    }
    const childNavClick = (childnavpath) => {
        localStorage.setItem('ChildNavs', childnavpath);
        SetChildNavs(childnavpath)
    }
    useEffect(() => {
        getUserData();
        getParentNav();
    }, [])

    return (
        <>
            <div className="dashboard-layout">
                <header>
                    <div className="Dashboard-brand">
                        <img className="DashBoard-Logo" src={require("./images/Logo.png")} alt="logo" />
                    </div>
                    <div className="ms-auto menu-right">
                        <MdCircleNotifications className="DashBoard-Notification" />
                        <img className="DashBoard-Photo" src={require("./images/Photo.png")} alt="userPhoto" />
                        <RiLogoutCircleRLine className="DashBoard-Photo" onClick={AlertLogout} />
                    </div>
                </header>
                <aside>
                    <nav className="sidebar-main">

                        <>
                            <NavLink to="/dashboard/users" className={localStorage.getItem('ParentNav') == "Appointments" ? "sidebar-icon acitve" : "sidebar-icon"}><div onClick={(e) => handleNav(e, "Appointments")} className={localStorage.getItem('ParentNav') == "Appointments" ? "navitem active" : "navitem"} data-parentnav="Appointments" >
                                <FaUsers className="sidebar-icon" />
                                <p className="sidebar-name">Users</p>
                            </div></NavLink>
                            <NavLink to="/dashboard/masters/Auditions" className={localStorage.getItem('ParentNav') == "marketing" ? "sidebar-icon acitve" : "sidebar-icon"} ><div onClick={(e) => handleNav(e, "marketing")} className={localStorage.getItem('ParentNav') == "marketing" ? "navitem active" : "navitem"} data-parentnav="marketing" >
                                <HiOutlineSpeakerphone className="sidebar-icon" />
                                <p className="sidebar-name">Marketing</p>
                            </div></NavLink>

                            {/* <NavLink to="/dashboard/admin/profile" className={localStorage.getItem('ParentNav') == "reports" ? "sidebar-icon acitve" : "sidebar-icon"}><div onClick={(e) => handleNav(e, "reports")} className={localStorage.getItem('ParentNav') == "reports" ? "navitem active" : "navitem"} data-parentnav="reports" >
                                        <BiStore className="sidebar-icon" />
                                        <p className="sidebar-name">Stores</p>
                                    </div></NavLink> */}
                        </>
                    </nav>
                </aside>
                <div className="dashboard-content" id="scroll" ref={scrollableContainerRef}>
                    {children}
                </div>
            </div>
            <Modal
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Body className="success-icon">
                    <h5 className="successmsg">Are you sure you want to logout ?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button variant="primary" className="btn btn-primary" onClick={() => setSmShow(false)}>
                        Cancel
                    </button>
                    <button variant="primary" className="btn btn-primary" onClick={handleLogout}>
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export { DashboardLayout }
