import React, { useState, useEffect } from "react";
import "./Post.css"
import { MdOutlineMoreHoriz } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import axiosConfig from "../../Service/axiosConfig"


const Actions = ({item,handleHideEvent}) => {
    console.log('item:')
    console.log(item)
    let is_own_property = false
    if(item.profile.hcc_id == localStorage.getItem("hcc_id")){
        is_own_property = true
    }

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleDelete = () => {
        axiosConfig.delete(`/accounts/model-images/${item.id}/`).then(res => {
            console.log(res)
            console.log('deleted')
            handleHideEvent()
        }).catch(error => {
            console.log(error.response.data.error)
        })
        closeModal();
    };

    const copyToClipboard = async (copy_item) => {
        if(copy_item== "profile"){
            try {
                await navigator.clipboard.writeText(`${window.location.origin}/profile/${item.profile.hcc_id}`);
                console.log('Text copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy text to clipboard:', err);
            }
        }else{
            try {
                await navigator.clipboard.writeText(`${window.location.origin}/p/${item.uid}`);
                console.log('Text copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy text to clipboard:', err);
            }
        }

      };
      
    

      const shareContent = async (share_to) => {
        try {
            let text =  `${window.location.origin}/p/${item.uid}`
            const shareUrl = encodeURIComponent(text);

            if(share_to == "whatsapp"){
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            }else if(share_to == "fb"){
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            }else{
                if (navigator.share) {
                  // Use Web Share API if available
                  await navigator.share({
                    title: 'Share Post',
                    text: `${window.location.origin}/p/${item.uid}`,
                  });
                }
            }
        } catch (err) {
          console.error('Failed to share content:', err);
        }
      };
      

      
    return (
        <>
            <div className="dropdown">
                <button type="button" className="btn btn-default" data-bs-toggle="dropdown">
                    <MdOutlineMoreHoriz className="more-icon" />
                </button>
                <ul className="dropdown-menu">
                    {is_own_property ?
                        <>
                            <li><a className="dropdown-item" href="#">Edit</a></li>
                            <li><a className="dropdown-item" href="#" onClick={openModal}>Delete</a></li>
                        </>
                        :
                        <>
                            <li><a className="dropdown-item" href="#">Report</a></li>
                            <li><a className="dropdown-item" href="#">Unfollow</a></li>
                        </>
                    }
                    <li><a className="dropdown-item" onClick={() => copyToClipboard("profile")} href="#">Copy Profile</a></li>
                    <li><a className="dropdown-item" onClick={() => copyToClipboard("post")} href="#">Copy Link</a></li>
                    <li><a className="dropdown-item hide-mobile" onClick={() => shareContent("whatsapp")} href="#">Share to whatsapp</a></li>
                    <li><a className="dropdown-item hide-mobile" onClick={() => shareContent("fb")} href="#">Share to facebook</a></li>
                    <li><a className="dropdown-item" onClick={() => shareContent("share")} href="#">Share</a></li>
                </ul>
            </div>
            <Modal
                show={isModalOpen}
                onHide={closeModal}
                contentLabel="Delete Confirmation"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="success-icon">
                    <h5>Are you sure you want to delete this post?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button variant="default" className="btn btn-default" onClick={closeModal}>
                        Cancel
                    </button>
                    <button variant="danger" className="btn btn-danger" onClick={handleDelete}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export { Actions };