import React, { useState } from 'react';
import axiosConfig from '../../Service/axiosConfig';
import AvatarEditor from 'react-avatar-editor';
import Resizer from 'react-image-file-resizer';

const EditDp = ({ ProfileData, handleLoginEvent }) => {
  const [Data, setData] = useState({});
  const [image, setImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(URL.createObjectURL(file));
    setData({ ...Data, profilePic: file });
  };

  // const handleSave = async () => {
  //   if (editor) {
  //     const canvas = editor.getImage();
  //     canvas.toBlob(async (blob) => {
  //       if (blob) {
  //         const formData = new FormData();
  //         formData.append('profilePic', blob);
  //         formData.append('profile_id', ProfileData.id);
  //         axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
  //         try {
  //           await axiosConfig.post('/accounts/api/editprofile/', formData);
  //           handleLoginEvent();
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       }
  //     });
  //   }
  // };
  const handleSave = async () => {
    if (editor) {
      const canvas = editor.getImage();
      canvas.toBlob(async (blob) => {
        if (blob) {
          try {
            Resizer.imageFileResizer(
              blob,
              400,
              400,
              'JPEG',
              100,
              0,
              async (resizedBlob) => {
                if (resizedBlob) {
                  const formData = new FormData();
                  formData.append('profilePic', resizedBlob);
                  formData.append('profile_id', ProfileData.id);
                  axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
                  try {
                    await axiosConfig.post('/accounts/api/editprofile/', formData);
                    handleLoginEvent();
                  } catch (error) {
                    console.log(error);
                  }
                }
              },
              'blob'
            );
          } catch (error) {
            console.log('Resizing error:', error);
          }
        }
      });
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="edit-profile-container">
            {image && (
              <AvatarEditor
                ref={(ref) => setEditor(ref)}
                image={image}
                width={200}
                height={200}
                border={50}
                color={[255, 255, 255, 0.8]} // RGBA
                scale={scale}
                onImageChange={() => { }}
              />
            )}
          </div>
        </div>
        {image &&
          <div className="col-sm-12">
            <div className="form-group">
              <div className='zoomaction'>
                <label>Zoom:</label>
                <input
                  type="range"
                  min="1"
                  max="3"
                  step="0.1"
                  value={scale}
                  onChange={(e) => setScale(parseFloat(e.target.value))}
                />
              </div>
            </div>
          </div>
        }
        <div className="col-sm-12">
          <div className="form-group">
            <label>Profile Picture:</label>
            <input
              type="file"
              accept="image/*"
              className="form-control"
              name="profilePic"
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>
      <button onClick={handleSave} className="btn btn-primary">
        Save
      </button>
    </div>
  );
};

export { EditDp };
