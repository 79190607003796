import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axiosConfig from '../Service/axiosConfig';

function PersonalProofs({Data}){ 

    return (      <div style={styles.flexcontainer}>
                    <div key={Data.id} style={styles.card}>
                        <img src={Data?.govt_id_proof_front_copy} alt={Data?.govt_id_proof_front_copy} style={styles.image} />
                        <p style={styles.role}>Front Copy</p>
                    </div>
                    <div style={styles.card}>
                    <img src={Data?.govt_id_proof_back_copy} alt={Data?.govt_id_proof_back_copy} style={styles.image} />
                    <p style={styles.role}>Back Copy</p>
                </div>
                </div>
    );
}

const styles = {
    flexcontainer: {
        display: 'flex',
        flexWrap: 'wrap', // Enable wrapping of cards
       
    },
    card: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '20px',
        width: 'calc(40% - 20px)', // Ensure 4 cards per row with space between
        margin: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    },
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
    },
    headingLeft: {
        fontSize: '20px',
        margin: '10px 0',
        textAlign: 'left',
    },
    role: {
        fontSize: '1.2rem',
        color: '#555',
    },
};
export default PersonalProofs;
