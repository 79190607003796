import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json"
import { MdOutlinePhone } from "react-icons/md"
import axiosConfig from "../Service/axiosConfig"
import { useParams, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AuditionRequirements } from "./AudiRequirements";
import DataTable from 'react-data-table-component';
import Select from 'react-select';

const AuditionInfo = ({ Code }) => {
    let { id } = useParams();
    console.log(id, "iddddd")
    const navigate = useNavigate();
    const [key, setKey] = useState('Audition Info');
    const [readOnly, setReadOnly] = useState(true);
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [Data, SetData] = useState({});
    const [ApplicationData, SetApplicationData] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(30);
    const [Message, setMessage] = useState("")
    const GetData = () => {
        setLoading(true)
        axiosConfig.get(`/accounts/auditions/${id}/`).then(res => {
            console.log(res.data)
            SetData(res.data)
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }
    const GetAuditionList = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`accounts/audition-applications/?page=${currentPage}&page_size=${RowPerPage}&profile=&audition=${id}&application_status=`)
            console.log(responce.data)
            setTotalRows(responce.data.count)
            const profiles = responce.data.results.map(item => {
                const profile = item.profile;
                profile.Status = `${item.application_status}`;
                return profile
            });
            console.log(profiles)
            SetApplicationData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const formSubmit = (formData, setFieldErrors) => {
        console.log(Data, "data", formData)
        axiosConfig.patch(`/accounts/auditions/${Data.id}/`, formData).then(res => {
            console.log(res)
            GetData()
            setMessage("Audition Updated SuccessFully!")
            setpopupType("success");
        }).catch(error => {
            console.log(error.response)
        })
    };
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const calculateAge = (dob) => {
        const dobDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
        }
        return age;
    };
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "image",
            selector: row => <div onClick={() => handleNavigate(row?.profile?.id, "profile")}>
                <img className="profile-pic" src={row.profile.profile_pic && row.profile?.profile_pic} alt="profile pic" />
            </div>,
            sortable: true
        },
        {
            name: "Name",
            selector: "name",
            cell: row => <div onClick={() => handleNavigate(row?.profile?.id, "profile")}>{row.profile?.first_name}</div>,
            sortable: true
        },
        {
            name: "HCC ID",
            selector: "hcc_id",
            cell: row => row.profile?.hcc_id,
            sortable: true
        },
        {
            name: "Role",
            selector: "designation",
            cell: row => row.profile?.designation ? row.profile?.designation : "----",
            sortable: true
        },
        {
            name: "Age",
            selector: "dob",
            cell: row => <div>{calculateAge(row.profile?.dob)}</div>,
            sortable: true
        },
        {
            name: "Gender",
            selector: "gender",
            cell: row => <div>{row.profile?.gender}</div>,
            sortable: true
        },
        {
            name: "Location",
            selector: "location",
            cell: row => <div>{row?.profile?.present_state}-{row?.profile?.present_city}</div>,
            sortable: true
        },
        {
            name: "Status",
            selector: "application_status",
            cell: row => row.application_status,
            sortable: true
        }
    ]
    const handleNavigate = (link, type) => {
        console.log(link, type);
        if (type === "profile") {
            navigate(`/dashboard/users/${link}/edit`)
        }
        if (type === "back") {
            navigate(`/dashboard/masters/Auditions/`)
        }
    }
    useEffect(() => {
        GetData()
        GetAuditionList()
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="client-options">
                    <BiArrowBack onClick={() => handleNavigate("", "back")} className="option-icon" />
                </div>
                <div className="client-info">
                    <div className="contact-detile">
                        Banner Name : <div className="data-text">{Data.banner_name}</div>
                    </div>
                    <div className="contact-detile">
                        Contact Name : <div className="data-text">{Data.contact_name}</div>
                    </div>
                    <div className="contact-detile">
                        Contact Designation Name : <div className="data-text">{Data.contact_designation}</div>
                    </div>
                    <div className="contact-detile">
                        Contact Mobile No : <a href={`tel:+${Data.contact_mobile_no}`} className="data-text">{Data.contact_mobile_no}</a>
                    </div>
                </div>
                <div className="client-form">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    // className="mb-3"
                    >
                        <Tab eventKey="Audition Info" title="Audition Info">
                            <FormParser modelObject={Masters.Audition} formData={Data} formSubmit={formSubmit} error={Error} readOnly={readOnly} setReadOnly={setReadOnly} />
                        </Tab>
                        <Tab eventKey="Status" title="Status">
                            <>
                                <div className="d-flex">
                                    <div>Audition Status : </div>
                                    <h5> {Data.approval_status ? Data.approval_status : ""}</h5>
                                </div>
                                <div className="d-flex">
                                    <div>Comments : </div>
                                    {/* <textarea className="form-control" rows="2" placeholder="Comments"></textarea> */}
                                    <h5> {Data.approval_message ? Data.approval_message : "--"}</h5>
                                </div>
                            </>
                        </Tab>
                        <Tab eventKey="Requirements" title="Requirements">
                            <AuditionRequirements />
                        </Tab>
                        {/* <Tab eventKey="Applications" title="Applications">
                            <>
                                <div className="col-md-4 sub-filter">
                                    <Select
                                    // options={categoryOptions}
                                    // placeholder={selectedCategoryId ? "" : "Select Category"}
                                    // value={selectedCategoryId.category}
                                    // onChange={(selectedOption) => handleCategoryFilterChange(selectedOption, "category")}
                                    // required
                                    />
                                </div>
                                <DataTable columns={columns} data={ApplicationData} pagination paginationTotalRows={totalRows}
                                    paginationPerPage={RowPerPage}
                                    paginationDefaultPage={currentPage}
                                    onChangePage={(page) => setCurrentPage(page)}
                                    paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                    paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                    onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                    // onSort={handleSort}
                                    paginationServer 
                                    // fixedHeader subHeader 
                                    customStyles={tableCustomStyles} progressPending={loading}
                                    progressComponent={<div className="loader-container">
                                        <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                                    </div>}
                                />
                            </>
                        </Tab> */}
                    </Tabs>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message={Message} error={error} />
                }
            </DashboardLayout >
        </>
    )

}
export { AuditionInfo }