import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axiosConfig from '../../src/Service/axiosConfig';

function ProfessionalProofs() {
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([]);

    const GetData = () => {
        setLoading(true);
        axiosConfig.get(`/accounts/verified-profile-designations/?verified_profile=${id}`)
            .then(res => {
                SetData(res.data.results);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        GetData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div style={styles.container}>
            {Data.length === 0 ? (
                <p>No data available</p>
            ) : (
                Data.map((item) => (
                    <div key={item.id} style={styles.card}>
                        <h2 style={styles.headingLeft}>{item.designation_union_name}</h2>
                        <img src={item.designation_union_copy} alt={item.designation_union_name} style={styles.image} />
                        <p style={styles.role}>Frame Name: {item.role.frame_name}</p>
                    </div>
                ))
            )}
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap', // Enable wrapping of cards
       
    },
    card: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '20px',
        width: 'calc(25% - 20px)', // Ensure 4 cards per row with space between
        margin: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    },
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
    },
    headingLeft: {
        fontSize: '20px',
        margin: '10px 0',
        textAlign: 'left',
    },
    role: {
        fontSize: '1.2rem',
        color: '#555',
    },
};

export default ProfessionalProofs;
